import * as Sentry from '@sentry/browser';
import { setErrorPage } from './db';
import { firestoreHelper, functionsHelper } from '../firebase/firebase';
import { setDoc } from 'firebase/firestore';
import { APPS_DOMAIN, APPS_DOMAIN_OLD } from 'constants/auth';
import { usePlacesWidget } from 'react-google-autocomplete';
import { statesOptions } from 'constants/helpers';

const {
  db,
  runTransaction,
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  where,
  updateDoc,
} = firestoreHelper;
const { functions, httpsCallable } = functionsHelper;

// Check if user already exists in the DB.
export const checkExistingUser = (email, facebook) => {
  const checkExistingUser = httpsCallable(
    functions,
    'groupAuthHelpers-checkExistingUser'
  );
  return checkExistingUser({ email, facebook });
};

// Check email domain.
export const doVerifyEmailDomain = (email) => {
  const verifyEmailDomain = httpsCallable(
    functions,
    'groupAuthHelpers-verifyEmailDomain'
  );

  return verifyEmailDomain({ email });
};

/**
 * Save Identification helper.
 *
 * @param params
 *   type string on of "lighthouse", "email", "quiz", "veriff", "manual".
 *   status string on of "incomplete", "pending" or "failed",
 *   email optional parameter for email verification.
 * @returns {Promise<firebase.functions.HttpsCallableResult>}
 */
export const saveIdentification = (params) => {
  const saveIdentification = httpsCallable(
    functions,
    'groupVerifyVerification-saveIdentification'
  );

  return saveIdentification(params);
};

// Get authToken.
export const doGetCustomToken = () => {
  const getCustomToken = httpsCallable(
    functions,
    'groupAuthAccounts-getCustomToken'
  );

  return getCustomToken();
};

// Get authToken.
export const doGetCsrfToken = () => {
  const getCsrfToken = httpsCallable(
    functions,
    'groupAuthAccounts-getCsrfToken'
  );

  return getCsrfToken();
};

// Create support ticket.
export const createVerificationSupportTicket = (type, info) => {
  const createVerificationSupportTicketOnCall = httpsCallable(
    functions,
    'groupVerifyVerification-createVerificationSupportTicketOnCall'
  );

  return createVerificationSupportTicketOnCall({
    contactType: type,
    contactInfo: info,
  });
};

// Retrieve JWT Kustomer token.
export const getJWTKustomerToken = () => {
  const JWTKustomerToken = httpsCallable(
    functions,
    'groupAuthKustomer-jwtAuth'
  );
  return JWTKustomerToken();
};

// Create new documents upload ticket.
export const createDocumentsUploadSupportTicket = () => {
  const createDocumentsUploadSupportTicketOnCall = httpsCallable(
    functions,
    'groupArsenalHelpers-createDocumentsUploadSupportTicketOnCall'
  );

  return createDocumentsUploadSupportTicketOnCall();
};

// Update the current user record.
export const addAccountInfo = async (account, uid) => {
  const userDoc = doc(db, 'veterans', uid);
  try {
    await runTransaction(db, async (transaction) => {
      // Prepare updated doc reference.
      const updateDoc = await transaction.get(userDoc);
      if (!updateDoc.exists()) {
        throw 'Document does not exist!';
      }

      // Trim user names.
      account?.firstname && (account.firstname = account.firstname.trim());
      account?.lastname && (account.lastname = account.lastname.trim());

      let changedTime = {
        changed: new Date().getTime(),
        modifiedBy: 'verifyApp',
        revisionId: updateDoc.data().revisionId + 1,
      };

      transaction.set(
        userDoc,
        {
          ...account,
          ...changedTime,
        },
        { merge: true }
      );
    });
  } catch (error) {
    console.log('Transaction failed: ', error);
  }
};

// Update/Write session into the DB.
export const writeSession = async (updates, sessionId) => {
  const sessionDoc = doc(db, 'sessions', sessionId);
  return setDoc(sessionDoc, updates, { merge: true });
};

// Initiate trust score code.
export const getTrustScore = () => {
  const externalPhoneVerification = httpsCallable(
    functions,
    'groupAuthPayfone-externalPhoneVerification'
  );
  return externalPhoneVerification();
};

// Identify user.
export const doIdentify = () => {
  const identify = httpsCallable(functions, 'groupAuthAnalytics-identify');
  identify().catch((error) => {
    Sentry.captureException(error);
    console.error(error);
  });
};

// Get all the fields in the user document from DB.
export const doRetrieveCustomToken = (sessionId) => {
  const cutomTokenDoc = doc(
    db,
    'sessions',
    sessionId,
    'login_session',
    sessionId
  );
  return getDoc(cutomTokenDoc).then((doc) => {
    if (doc.exists) {
      return doc.data();
    } else {
      throw new Error('Collection does not exist');
    }
  });
};

// Send .mil email code.
export const sendEmailCode = (sessionId, affinity) => {
  const verifyMilAddress = httpsCallable(
    functions,
    'groupVerifyVerification-verifyMilAddress'
  );

  return verifyMilAddress({ sessionId, affinity });
};

// Verify .mil email code.
export const verifyEmailCode = (sessionId, code) => {
  const verifyMilAddressCode = httpsCallable(
    functions,
    'groupVerifyVerification-verifyMilAddressCode'
  );
  return verifyMilAddressCode({
    sessionId,
    code,
  });
};

// Get branch from edu email.
export const getBranchFromDomain = (emailDomain) => {
  const col = collection(db, 'terms_military_branches');
  const q = query(col, where('domain', '==', emailDomain));
  return getDocs(q).then((querySnapshot) => {
    let branchId = null;

    querySnapshot.forEach((doc) => {
      branchId = doc.id;
    });

    // Throw an error if there is no branch matching.
    if (!branchId) {
      throw new Error('We could not find branch corresponding to this email.');
    }

    // Return the matched branchId.
    return branchId;
  });
};

// Verify quiz results.
export const doVerifyQuiz = async (updates, sessionId, userID) => {
  const sessionDoc = doc(db, 'sessions', sessionId, 'verify_session', userID);
  return setDoc(sessionDoc, updates, { merge: true });
};

// Verify Social numbers results.
export const doVerifySocial = async (updates, sessionId, userID) => {
  const docToUpdate = doc(
    db,
    'sessions',
    sessionId,
    'social_verification_session',
    userID
  );
  await updateDoc(docToUpdate, updates);
};

// Duplicate session.
export const duplicateSessionId = (data) => {
  const duplicateSessionId = httpsCallable(
    functions,
    'groupArsenalVerification-duplicateSessionId'
  );

  duplicateSessionId(data).catch((error) => {
    Sentry.captureException(error);
    console.error(error);
  });
};

// Check if name is valid
export const isValidName = (text) => {
  const reg = RegExp("^[ a-zA-Z'.-]*$");
  if (!!text && reg.test(String(text))) {
    return true;
  } else {
    return false;
  }
};

// Return date in string format(yyyy-mm-dd). Example: '1992-08-22'.
export const dateToString = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
};

// Check Military Status.
/**
 * Request data:
 * @typedef {Object} data
 * @property {string} [gender] - For gender, only 'm' or 'f' will help the search currently.
 * @property {string} firstName
 * @property {string} [middleName]
 * @property {string} lastName - Some names may require special handling if a Veteran is not found.
 * @property {string} zipCode
 * @property {string} city - City field can accept a string that starts with one or more uppercase or lowercase letters, followed by zero or more occurrences of a space or hyphen and one or more uppercase or lowercase letters, and end the string there.
 * @property {string} state - State field can accept a string that starts with one or more uppercase or lowercase letters, followed by zero or more occurrences of a space or hyphen and one or more uppercase or lowercase letters, and end the string there.
 * @property {string} country - Country field can accept a string that starts with one or more uppercase or lowercase letters, followed by zero or more occurrences of a space or hyphen and one or more uppercase or lowercase letters, and end the string there.
 * @property {string} birthDate - Birth date can accept dates in YYYY-MM-DD format.
 * @property {string} streetAddressLine1 - Address line 1 can accept, One or more uppercase or lowercase letters, digits, spaces, commas, apostrophes, or hyphens.
 * @property {string} [streetAddressLine2] - Address line 2 can accept, One or more uppercase or lowercase letters, digits, spaces, commas, apostrophes, or hyphens.
 * @property {string} [streetAddressLine3] - Address line 3 can accept, One or more uppercase or lowercase letters, digits, spaces, commas, apostrophes, or hyphens.
 * @property {string} [homePhoneNumber]
 * @property {string} [mothersMaidenName]
 * @property {string} [birthPlaceCity] - Birth place city can accept, One or more uppercase or lowercase letters, digits, spaces, commas, apostrophes, or hyphens.
 * @property {string} [birthPlaceState] - Birth place state can accept, One or more uppercase or lowercase letters, digits, spaces, commas, apostrophes, or hyphens.
 * @property {string} [birthPlaceCountry] - Birth place country can accept, One or more uppercase or lowercase letters, digits, spaces, commas, apostrophes, or hyphens.
 *
 * Successful Response:
 * @typedef {Object} result
 * @property {string} id - Confirmation ICN
 * @property {("confirmed"|"not confirmed")} veteran_status - Whether the system could confirm the Veteran status of an individual based on traits
 * @property {string} [not_confirmed_reason] - Defines reason for a not confirmed status response.
 *
 * Error Response:
 * @typedef {Object} APIErrorDetails
 * @property {string} title - Error title
 * @property {string} detail - Detailed error message
 * @property {string} code - Error code
 * @property {string} status - HTTP status code
 *
 * @typedef {Object} AuthorizationError
 * @property {string} message
 */

export const doVerifyMilitaryStatus = (data) => {
  const verifyMilitaryStatus = httpsCallable(
    functions,
    'groupVerifyLighthouse-verifyUserV1'
  );
  return verifyMilitaryStatus(data);
};

// Calculate trustScore.
export const calculateTrustScore = () => {
  const calculateTrustScore = httpsCallable(
    functions,
    'groupVerifyProve-calculateTrustScore'
  );

  return calculateTrustScore();
};

/**
 * Error handler helper.
 *
 * @param error
 * @param dispatch
 */
export const handleError = (error, dispatch) => {
  Sentry.captureException(error);
  console.error(error);
  dispatch(
    setErrorPage(
      'Please try again later',
      false,
      'error',
      'Error occurred: ' + error?.message,
      'Try again',
      false,
      false,
      true
    )
  );
};

//Check if is WeSalute domain.
export const isNewTld = () => {
  const hostTld = () => {
    const parts = window.location.hostname.split('.');
    const tld = parts.slice(-2).join('.');
    return [APPS_DOMAIN, APPS_DOMAIN_OLD].includes(tld) ? tld : APPS_DOMAIN;
  };
  return hostTld() === APPS_DOMAIN;
};

/**
 * Use Address Autocomplete.
 *
 * Retrieve the address grom google map API.
 *
 * @param onChange: (name: string, value: string) => void
 */
export const useAddressAutocomplete = (onChange) => {
  const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_JS_KEY;

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: mapsApiKey,
    onPlaceSelected: (place) => {
      if (!onChange) return;
      const address = {};
      const addressNameFormat = {
        country: { type: 'short_name' },
        street_number: { type: 'short_name' },
        route: { field: 'line1', type: 'long_name' },
        locality: { field: 'city', type: 'long_name' },
        administrative_area_level_1: {
          field: 'stateCode',
          type: 'short_name',
          field2: 'stateName',
          type2: 'long_name',
        },
        postal_code: { type: 'short_name' },
        postal_code_suffix: { type: 'short_name' },
      };
      for (const component of place.address_components) {
        const type = component.types[0];
        const map = addressNameFormat[type];
        if (map) {
          address[map.field || type] = component[map.type];
          if (map.field2 && map.type2) {
            address[map.field2] = component[map.type2];
          }
        }
      }

      const line1 =
        [address['street_number'], address['line1']]
          .filter((v) => v)
          .join(' ') || '';
      ref.current.value = line1;
      line1 && onChange('street', line1);
      address['city'] && onChange('city', address['city']);
      address['stateCode'] &&
        address['stateName'] &&
        onChange(
          'state',
          statesOptions.find((state) => state.id === address['stateCode'])
        );
      const postal_code = [
        address['postal_code'],
        address['postal_code_suffix'],
      ]
        .filter((v) => v)
        .join('-');
      onChange('zipcode', postal_code ?? '');
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });
  return {
    enabled: !!mapsApiKey,
    ref,
    autocompleteRef,
  };
};

// Check if user's phone already exists in the DB.
export const checkExistingPhone = (phone) => {
  const checkExistingPhone = httpsCallable(
    functions,
    'groupAuthHelpers-checkExistingPhone'
  );
  return checkExistingPhone({ phone });
};

export const setAffinityAndSubgroup = (data) => {
  const setAffinity = httpsCallable(
    functions,
    'groupVerifyVerification-setAffinityAndSubgroup'
  );
  return setAffinity(data);
};

/**
 * Make Firebase error message mapped to human-readable analogue.
 *
 * @param error
 */
export const mapFirebaseError = (error) => {
  // Maps Firebase error to human readable analogue.
  switch (error.message) {
    case 'Firebase: Error (auth/account-exists-with-different-credential).':
      error.message =
        'This phone number is linked to an existing account. Please enter a different number. If this is an error, please contact customer support for further assistance.';
      break;
    case 'Firebase: Error (auth/too-many-requests).':
      error.message =
        "You've had too many attempts to verify. Please contact customer support for further assistance.";
      break;
    case 'Firebase: Error (auth/invalid-verification-code).':
      error.message = 'Verification code entered is invalid. Please try again.';
      break;
    case 'Firebase: Error (auth/internal-error).':
      error.message =
        'An error has occurred. Please try again. If the issue persists, please contact customer support for further assistance.';
      break;
    case 'Firebase: Error (auth/network-request-failed).':
      error.message = 'Network request failed. Please try again.';
      break;
    case 'Firebase: Error (auth/argument-error).':
      error.message =
        'A browser error has occurred. If this issue persists, please try a different browser, or contact customer support for further assistance.';
      break;
    case 'Firebase: Error (auth/code-expired).':
      error.message = 'Verification code expired.';
      break;
    case 'Firebase: Error (auth/invalid-phone-number).':
      error.message = 'Invalid phone number.';
      break;
  }
};
