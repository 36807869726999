import store from '../store';
import * as routes from '../constants/routes';
import { relation } from '../constants/affinityAndSubgroup';
import { isNewTld } from './helpers';
import { APPS_DOMAIN, APPS_DOMAIN_OLD } from '../constants/auth';

/**
 * Helper flag to enable and disable VerifyApp phone verification.
 *
 * @type {boolean}
 */
export const phoneVerificationEnabled = true;

/**
 * Helper to log routing actions
 * on all environments except prod.
 */
const wesaluteLog = (...args) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    console.log(args);
  }
};
/**
 * Common get next route method.
 *
 * @returns {null}
 */
export default function getCustomRoute(history, accountData = null) {
  // Get redux store state.
  const state = store.getState();

  if (!accountData) {
    accountData = state.accountState.account;
  }

  const editMode = state.sessionDBState.data.urlQuery?.edit;
  const reverifyMode = state.sessionDBState.data.urlQuery?.reverify;
  // Get current hash value.
  const hash = state.sessionDBState.data.hash;

  // Get Common identity and military identification statuses.
  const {
    identityStatus,
    militaryStatus,
    atomicStatus,
    emailStatus,
    lighthouseStatus,
    proveStatus,
    quizStatus,
    sduMilitaryStatus,
    veriffStatus,
  } = getAllIdentificationStatuses(accountData);

  /**
   * Veteran users account routes helper.
   */
  const veteransRoutes = () => {
    const skipMilVerification = militaryStatus === 'verified';
    if (militaryStatus !== 'verified') {
      // If user didn't visit lighthouse verification page, then we allow access.
      if (!lighthouseStatus && !skipMilVerification) {
        wesaluteLog('veteran lighthouse verification');
        history.push(routes.LIGHTHOUSE_VERIFICATION);
      } else if (
        (!accountData?.branch ||
          !accountData?.paygrade ||
          !accountData?.rank) &&
        !skipMilVerification
      ) {
        wesaluteLog('veteran military creds');
        history.push(routes.MILITARY_CREDENTIALS);
      } else if (
        (!quizStatus || ['incomplete', 'pending'].indexOf(quizStatus) !== -1) &&
        !skipMilVerification
      ) {
        wesaluteLog('veteran quiz');
        history.push(routes.QUIZ);
      } else if (
        !sduMilitaryStatus ||
        ['incomplete', 'pending'].indexOf(sduMilitaryStatus) !== -1
      ) {
        wesaluteLog('veteran manual verification');
        history.push(routes.MANUAL_VERIFICATION);
      }
    } else {
      wesaluteLog('veteran identity   verification');
      indetityVerification();
    }
  };

  /**
   * Academy users account routes helper.
   */
  const academyRoutes = () => {
    // Check if veterans quiz has been passed or failed.
    if (militaryStatus !== 'verified') {
      // Set atomic financial for all active but non academy users.
      if (!accountData?.branch) {
        wesaluteLog('academy military creds');
        // Check active_duty and commissioned users for branch grade and rank prerequisites.
        history.push(routes.MILITARY_CREDENTIALS);
      } else if (
        !emailStatus ||
        ['incomplete', 'pending'].indexOf(emailStatus) !== -1
      ) {
        wesaluteLog('academy email verification');
        history.push(routes.ACADEMY);
      } else if (
        !quizStatus ||
        ['incomplete', 'pending'].indexOf(quizStatus) !== -1
      ) {
        wesaluteLog('academy quiz verification');
        history.push(routes.QUIZ);
      } else if (
        !sduMilitaryStatus ||
        ['incomplete', 'pending'].indexOf(sduMilitaryStatus) !== -1
      ) {
        wesaluteLog('academy manual verification');
        history.push(routes.MANUAL_VERIFICATION);
      }
    } else {
      wesaluteLog('academy identity verification');
      indetityVerification();
    }
  };

  /**
   * Active Duty users account routes helper.
   */
  const academyRoutesReverify = () => {
    if (!accountData?.branch) {
      wesaluteLog('academy reverify military credentials');
      // Check active_duty and commissioned users for branch grade and rank prerequisites.
      history.push(routes.MILITARY_CREDENTIALS);
    } else if (
      !emailStatus ||
      ['incomplete', 'pending'].indexOf(emailStatus) !== -1
    ) {
      wesaluteLog('academy reverify email verification');
      history.push(routes.ACADEMY);
    } else if (
      !quizStatus ||
      ['incomplete', 'pending'].indexOf(quizStatus) !== -1
    ) {
      wesaluteLog('academy reverify quiz verification');
      history.push(routes.QUIZ);
    } else if (
      !sduMilitaryStatus ||
      ['incomplete', 'pending'].indexOf(sduMilitaryStatus) !== -1
    ) {
      wesaluteLog('academy reverify manual verification');
      history.push(routes.MANUAL_VERIFICATION);
    } else if (sduMilitaryStatus === 'failed') {
      wesaluteLog('academy reverify contact us');
      history.push(routes.CONTACT_US);
    }
  };

  /**
   * Active Duty (Active Duty and Commissioned) helper.
   *
   * @param route
   */
  const activeDutyRoutes = async (route) => {
    // Check if veterans quiz has been passed or failed.
    if (militaryStatus !== 'verified') {
      if (
        !atomicStatus ||
        ['incomplete', 'pending'].indexOf(atomicStatus) !== -1
      ) {
        wesaluteLog('active duty atomic financial verification');
        history.push(routes.ATOMIC_FINANCIAL_VERIFICATION);
      } else if (
        !accountData?.branch ||
        !accountData?.paygrade ||
        !accountData?.rank
      ) {
        wesaluteLog('active duty military credentials');
        // Check active_duty and commissioned users for branch grade and rank prerequisites.
        history.push(routes.MILITARY_CREDENTIALS);
      } else if (
        !emailStatus ||
        ['incomplete', 'pending'].indexOf(emailStatus) !== -1
      ) {
        wesaluteLog('active duty email verification');
        history.push(route);
      } else if (
        !quizStatus ||
        ['incomplete', 'pending'].indexOf(quizStatus) !== -1
      ) {
        wesaluteLog('active duty quiz verification');
        history.push(routes.QUIZ);
      } else if (
        !sduMilitaryStatus ||
        ['incomplete', 'pending'].indexOf(sduMilitaryStatus) !== -1
      ) {
        wesaluteLog('active duty manual verification');
        history.push(routes.MANUAL_VERIFICATION);
      }
    } else {
      wesaluteLog('active duty identity verification');
      indetityVerification();
    }
  };

  /**
   * Active Duty (Active Duty and Commissioned) re-verify helper.
   *
   * @param route
   */
  const activeDutyRoutesReverify = async (route) => {
    if (
      !atomicStatus ||
      ['incomplete', 'pending'].indexOf(atomicStatus) !== -1
    ) {
      wesaluteLog('active duty reverify atomic financial verification');
      history.push(routes.ATOMIC_FINANCIAL_VERIFICATION);
    } else if (
      affinity !== 'academy' &&
      (!accountData?.branch || !accountData?.paygrade || !accountData?.rank)
    ) {
      wesaluteLog('active duty reverify military credentials');
      // Check active_duty and commissioned users for branch grade and rank prerequisites.
      history.push(routes.MILITARY_CREDENTIALS);
    } else if (
      !emailStatus ||
      ['incomplete', 'pending'].indexOf(emailStatus) !== -1
    ) {
      wesaluteLog('active duty reverify email verification');
      history.push(route);
    } else if (
      !quizStatus ||
      ['incomplete', 'pending'].indexOf(quizStatus) !== -1
    ) {
      wesaluteLog('active duty reverify quiz verification');
      history.push(routes.QUIZ);
    } else if (
      !sduMilitaryStatus ||
      ['incomplete', 'pending'].indexOf(sduMilitaryStatus) !== -1
    ) {
      wesaluteLog('active duty reverify manual verification');
      history.push(routes.MANUAL_VERIFICATION);
    } else if (sduMilitaryStatus === 'failed') {
      wesaluteLog('active duty reverify contact us');
      history.push(routes.CONTACT_US);
    }
  };

  /**
   * Relative users account routes helper.
   */
  const relativeRoutes = () => {
    if (militaryStatus !== 'verified') {
      const militaryRelative = accountData?.militaryRelative;
      if (
        (militaryRelative && !militaryRelative?.branch) ||
        !militaryRelative?.dateOfBirth ||
        !militaryRelative?.eraServed ||
        !militaryRelative?.firstName ||
        !militaryRelative?.lastName
      ) {
        wesaluteLog('relative military credentials');
        history.push(routes.MILITARY_CREDENTIALS);
      } else if (
        !sduMilitaryStatus ||
        ['incomplete', 'pending'].indexOf(sduMilitaryStatus) !== -1
      ) {
        wesaluteLog('relative manual verification');
        history.push(routes.MANUAL_VERIFICATION);
      }
    } else {
      wesaluteLog('relative identity verification');
      indetityVerification();
    }
  };

  /**
   * Common identity verification helper.
   */
  const indetityVerification = () => {
    if (
      !veriffStatus ||
      ['incomplete', 'pending'].indexOf(veriffStatus) !== -1
    ) {
      wesaluteLog('id verification');
      history.push(routes.ID_SCAN);
    } else if (veriffStatus === 'failed') {
      wesaluteLog('contact us');
      history.push(routes.CONTACT_US);
    }
  };

  const affinity = getAffinity(accountData);

  // Send user to verify path if all verifications done.
  if (reverifyMode || accountData?.flags?.reverify) {
    if (accountData?.flags?.reverify !== 'verified') {
      // Allow affinity changes in reverification mode.
      if (!affinity) {
        // Send to Affinity  page.
        history.push(routes.AFFINITY);
      } else {
        switch (affinity) {
          case 'active_duty':
            // Send to Active Duty routes.
            activeDutyRoutesReverify(routes.ACTIVE_DUTY);
            break;
          case 'academy':
            academyRoutesReverify();
            break;
          case 'commissioned':
            activeDutyRoutesReverify(routes.COMMISSIONED);
            break;
          // Added for the case when active user select non active affinity.
          case 'veteran':
            veteransRoutes();
            break;
          case 'relative':
            relativeRoutes();
            break;
          default:
            wesaluteLog('verify route');
            history.push(routes.VERIFY);
            break;
        }
      }
    } else {
      wesaluteLog('verify route');
      history.push(routes.VERIFY);
    }
  } else if (identityStatus === 'verified' && militaryStatus === 'verified') {
    // Edit mode makes sense only for already verified users.
    if (editMode) {
      wesaluteLog('military credentials edit mode');
      history.push(routes.MILITARY_CREDENTIALS);
    } else {
      wesaluteLog('verify route');
      // Send user to verify path if all verifications done.
      history.push(routes.VERIFY);
    }
  } else if (hash === '#welcome') {
    wesaluteLog('welcome screen');
    history.push(routes.WELCOME_SCREEN);
  } else if (
    identityStatus !== 'verified' &&
    militaryStatus === 'verified' &&
    veriffStatus !== 'failed'
  ) {
    wesaluteLog('id verification route');
    // Send user to Veriff if military verified and identity not.
    history.push(routes.ID_SCAN);
  } else if (
    (identityStatus !== 'verified' &&
      militaryStatus === 'verified' &&
      veriffStatus === 'failed') ||
    (identityStatus === 'revoked' && militaryStatus === 'revoked')
  ) {
    wesaluteLog('contact us route');
    // Send user to Veriff if military verified and identity not.
    history.push(routes.CONTACT_US);
  } else if (!accountData?.flags?.privacyAgreement) {
    wesaluteLog('privacy agreement route');
    // Send to Privacy agreement page.
    history.push(routes.STATIC_PRIVACY_AGREEMENT);
  } else if (!accountData?.flags?.whyWeAsk) {
    wesaluteLog('why we ask route');
    // Send to Why we ask page.
    history.push(routes.STATIC_WHY_WE_ASK);
  } else if (!accountData?.flags?.personalInformation) {
    wesaluteLog('personal information route');
    // Send to Personal information  page.
    history.push(routes.PERSONAL_INFORMATION);
  } else if (!affinity) {
    wesaluteLog('affinity route');
    // Send to Affinity  page.
    history.push(routes.AFFINITY);
  } else if (!accountData?.affidavit) {
    wesaluteLog('affidavit route');
    // Send to Affidavit  page.
    history.push(routes.AFFIDAVIT);
  } else if (
    phoneVerificationEnabled &&
    identityStatus !== 'verified' &&
    proveStatus !== 'failed'
  ) {
    wesaluteLog('phone verification route');
    // Send user to Veriff if military verified and identity not.
    history.push(routes.PHONE_VERIFICATION);
  } else if (affinity === 'veteran') {
    // Send to Veteran routes.
    veteransRoutes();
  } else if (affinity === 'active_duty') {
    // Send to Active Duty routes.
    activeDutyRoutes(routes.ACTIVE_DUTY);
  } else if (affinity === 'academy') {
    // Send to Academy routes.
    academyRoutes();
  } else if (affinity === 'commissioned') {
    // Send to Commissioned routes.
    activeDutyRoutes(routes.COMMISSIONED);
  } else if (affinity === 'relative') {
    // Send to Relative routes.
    relativeRoutes();
  }
}

/**
 * Helper to get all needed verifications.
 *
 * @param accountData
 * @returns {{
 *   sduMilitaryStatus: *,
 *   veriffStatus: *,
 *   atomicStatus: *,
 *   emailStatus: *,
 *   militaryStatus: *,
 *   identityStatus: *,
 *   lighthouseStatus: *,
 *   sduIdentityStatus: *,
 *   quizStatus: *,
 *   proveStatus: *
 * }}
 */
export const getAllIdentificationStatuses = (accountData) => {
  const identify = accountData?.identification;
  const militaryKey = getMilitaryKey(accountData);
  return {
    // Military statuses.
    militaryStatus: identify?.[militaryKey]?.status,
    atomicStatus: identify?.[militaryKey]?.verification?.atomicfi?.status,
    emailStatus: identify?.[militaryKey]?.verification?.email?.status,
    lighthouseStatus: identify?.[militaryKey]?.verification?.lighthouse?.status,
    quizStatus: identify?.[militaryKey]?.verification?.quiz?.status,
    sduMilitaryStatus: identify?.[militaryKey]?.verification?.manual?.status,
    // Identity statuses.
    identityStatus: identify?.identity?.status,
    sduIdentityStatus: identify?.identity?.verification?.manual?.status,
    veriffStatus: identify?.identity?.verification?.veriff?.status,
    proveStatus: identify?.identity?.verification?.prove?.status,
  };
};

/**
 * Helper to decide if user is fully verified.
 *
 * @param accountData
 * @returns {boolean|*}
 */
export const isFullyVerified = (accountData) => {
  const { militaryStatus, identityStatus } =
    getAllIdentificationStatuses(accountData);
  return militaryStatus === 'verified' && identityStatus === 'verified';
};

/**
 * Get affinity helper.
 *
 * @param accountData
 * @param milKey
 * @return {string}
 */
export const getAffinity = (accountData, milKey) => {
  if (!milKey) {
    milKey = getMilitaryKey(accountData);
  }
  let affinity = accountData?.identification?.[milKey]?.affinity;
  let subgroup = accountData?.identification?.[milKey]?.subgroup;

  if (!affinity) {
    return '';
  }

  // Fix for affinity change.
  if (affinity === 'former' || affinity === 'family') {
    subgroup = null;
  }

  const relationKeys = Object.keys(relation);
  return relationKeys.find((item) => {
    return (
      relation[item].affinity === affinity &&
      /* eslint-disable-next-line */
      relation[item].subgroup == subgroup
    );
  });
};

/**
 * @param accountData
 * @return {string}
 */
export const getMilitaryKey = (accountData) => {
  return accountData?.flags?.reverify &&
    accountData?.flags?.reverify !== 'verified'
    ? 'military_reverification'
    : 'military';
};

/**
 * Helper to get App URL.
 *
 * Needed because configData is not always available.
 *
 * @return {string}
 */
export const getAppUrl = (name) => {
  let url = `https://${name}`;
  const envs = ['dev', 'stage'];
  if (envs.indexOf(process.env.REACT_APP_ENV) !== -1) {
    url += process.env.REACT_APP_ENV === 'stage' ? '-stg' : '-dev';
  }
  // TODO change to new app domain once switched.
  const currentDomain = isNewTld() ? APPS_DOMAIN : APPS_DOMAIN_OLD;
  return url + '.' + currentDomain;
};

export const signOutHelper = () => {
  const state = store.getState();
  const brandUuid = state.sessionDBState.data.urlQuery?.brand_uuid;
  const editMode = state.sessionDBState.data.urlQuery?.edit;
  const hash = state.sessionDBState.data?.hash;
  const returnTo = state.sessionDBState.data.urlQuery.return_to;
  const reverifyMode = state.sessionDBState.data.urlQuery.reverify;
  const sessionDbId = state.sessionDBState.id;
  const providerToken = state.sessionDBState.data.urlQuery.providerToken;
  const signOutUser = state.sessionDBState.data.signOutUser;

  // Set computed return URL.
  let computedReturnUrl = getAppUrl('verify') + '/?sessionId=' + sessionDbId;
  if (process.env.REACT_APP_ENV === 'local') {
    computedReturnUrl = `https://verify-local.${APPS_DOMAIN}:3000/?sessionId=${sessionDbId}`;
  }

  if (editMode && (editMode === 'true' || editMode === '1' || editMode === 1)) {
    computedReturnUrl += '&edit=true';
  }

  if (
    reverifyMode &&
    (reverifyMode === 'true' || reverifyMode === '1' || reverifyMode === 1)
  ) {
    computedReturnUrl += '&reverify=true';
  }

  if (returnTo) {
    computedReturnUrl += '&return_to=' + returnTo;
  }

  if (brandUuid) {
    computedReturnUrl += '&brand_uuid=' + decodeURIComponent(brandUuid);
  }

  const token = providerToken ? providerToken : 'vaplatformdefault';

  // Set redirect URL.
  let redirectUrl = getAppUrl('auth');
  if (process.env.REACT_APP_ENV === 'local') {
    // TODO change to new app domain once switched.
    redirectUrl = `https://auth-local.${APPS_DOMAIN}:3001`;
  }
  if (signOutUser) {
    redirectUrl += '/signout';
  }
  redirectUrl +=
    '/?providerToken=' +
    token +
    '&sessionId=' +
    sessionDbId +
    '&return_to=' +
    encodeURIComponent(computedReturnUrl);

  // Add hash to redirect URL.
  if (hash) {
    redirectUrl += hash;
  }

  window.location.href = redirectUrl;
};
