import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './store';
import * as Sentry from '@sentry/browser';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
const pkg = require('../package.json');

// Prepare Sentry init object.
let sentryInit = {
  dsn: process.env.REACT_APP_SENTRYKEY,
  environment: process.env.REACT_APP_ENV,
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
  integrations: [new Sentry.browserTracingIntegration()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE)
    : 0.2,
};

// Prepare Sentry additional variables.
if (process.env.REACT_APP_GITHUB_SHA) {
  const env = process.env.REACT_APP_ENV || 'local';
  // Set App release.
  let APP_RELEASE = `${env}-${process.env.REACT_APP_GITHUB_SHA}`;
  // Get prod tag name.
  if (process.env.REACT_APP_ENV === 'prod') {
    APP_RELEASE = process.env.REACT_APP_GITHUB_REF;
  }
  sentryInit.release = `${pkg.name}-${APP_RELEASE}`;
  if (process.env.REACT_APP_ENV === 'dev') {
    console.log(sentryInit);
  }
}

/* Send error messages to Sentry & GCP SRE Project. */
Sentry.init(sentryInit);

ReactDOM.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App {...window.xprops} />
    </LocalizationProvider>
  </Provider>,
  document.getElementById('root')
);
