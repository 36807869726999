import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Preloader from '../../Preloader';
import useCustomLogin from '@wesalute/lib-auth-client/lib/useCustomLogin';
import * as firebase from '../../../firebase/firebase';
import { signOutHelper } from '../../../actions/getCustomRoute';
import { db } from '../../../actions';

const SignOut = () => {
  const { updateSession } = db;
  const dispatch = useDispatch();

  const [authUser, signOut] = useCustomLogin(
    firebase.authHelper,
    signOutHelper
  );

  useEffect(() => {
    if (authUser) {
      dispatch(
        updateSession({
          signOutUser: true,
          newSession: true,
        })
      );
      // Trigger signout flow.
      signOut();
    }
    // eslint-disable-next-line
  }, [authUser])

  return (
    <div className="page-signup">
      <Preloader errorTitle="Error loading Sign Out page" />
    </div>
  );
};

export default SignOut;
